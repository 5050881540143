import React from "react";
import { StyledHero } from "./style";
const Index = () => {
  return (
    <StyledHero>
      <div className="container-fluid">
        {/* <video src={Video} controls muted autoPlay></video> */}

        <iframe
          width="100%"
          height="611"
          src="https://www.youtube.com/embed/F38JeLfsUfM?&controls=0&loop=1&modestbranding=1&rel=0&autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </StyledHero>
  );
};

export default Index;
