import React from "react";
import { StyledTestimonial } from "./style";
import Icon from "../../assets/images/testimonial-comma.png";
import image_1 from "../../assets/images/testimonial-1.png";
const Index = () => {
  return (
    <StyledTestimonial>
      <div className="container">
        <img src={Icon} alt="icon" />
        <h3>What Our Client Says</h3>
        <div className="testimonial">
          <img src={image_1} alt="tesimonial" />
          <p>
            Dear Shahrzad Khanoom, Thank you so much for everything! We loved
            your beautiful words and radiant energy! So many people said
            wonderful things about you and the ceremony. Thank you for making it
            so special for me and Hooman! Warm Regards
          </p>
          <h6>FParastou</h6>
        </div>
      </div>
    </StyledTestimonial>
  );
};

export default Index;
