import React from "react";
import { Navbar } from "./style";
import Logo from "../../assets/images/toplogo.png";

const Index = () => {
  return (
    <Navbar>
      <div className="container">
        <img src={Logo} alt="Logo" />

        <button
          onClick={(e) => {
            e.preventDefault();
            window.location.replace("/#contact");
          }}
        >
          Contact Us
        </button>
      </div>
    </Navbar>
  );
};

export default Index;
