import styled from "styled-components";

export const StyledFooter = styled.div `
  background-color: #22140d;
  min-height: 9.5rem;
  display: flex;
  align-items: center;
  .container {
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding-block: 3rem;
    .copyright {
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 137.7%;
        color: white;
        @media (max-width: 383px) {
         padding-top: 3rem;
        }
      }
    }
    .logo {
      img {
        width: 16rem;
        height: 7.1rem;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      gap: 2rem;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;