import React from "react";
import { StyledOfficiant } from "./style";
import left from "../../assets/images/weddingleft.png";
import right from "../../assets/images/weddingright.png";
import image from "../../assets/images/officiant-right.png";

const Index = () => {
  return (
    <StyledOfficiant>
      <div className="container-fluid ">
        <div className="container">
          <div className="heading">
            <h3>Wedding Officiant</h3>
            <p>We meet, we talk, we exchange ideas and then you decide.</p>
          </div>
          <div className="flex-container">
            <div className="left">
              <h4>How it all began</h4>
              <p>
                I got my Marriage License in July 2005 and performed my first
                Wedding Ceremony on August 19th 2005. I will never forget that
                day.
                <br />
                <br />
                Since then I have had the pleasure of being a part of many
                ceremonies and I cherish each and every one of them. Each with
                its own theme, flavor and mood. As individual as the Bride and
                the Groom.
                <br />
                <br />
                Some like the ceremony to be short and sweet some long and
                detailed, some in Farsi, some in English, some in French. Some
                traditional, some contemporary, some serious, some humorous.{" "}
                <br />
                <br />
                Some prefer romantic wordings, some spiritual poetry, some have
                their own choice of rituals and for some a bit of everything
                works.
              </p>
            </div>
            <div className="right">
              <img src={image} alt="first" />
            </div>
          </div>
        </div>

        <img className="d-none d-md-block" src={left} alt="left" />
        <img className="d-none d-md-block" src={right} alt="right" />
      </div>
    </StyledOfficiant>
  );
};

export default Index;
