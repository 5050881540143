import React, { useState } from "react";
import { StyledForm } from "./style";
import formImg from "../../assets/images/form-pic.png";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Index = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    if (event.currentTarget.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      let data = {
        name: event.target[0].value,
        email: event.target[1].value,
        Phone: event.target[2].value,
        date: event.target[3].value,
        message: event.target[4].value,
      };

      data = Object.entries(data)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      axios
        .post("https://shahrzadweddings.com/mail.php?", data, {
          header: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(function (response) {
          console.log(response, "response");

          response?.data?.success === 1 && toast("Message Successfully sent!");
        })
        .catch((err) => {
          console.log("err", err);

          toast("Error occured while sending the message!");
        });
    }

    setValidated(true);
  };

  return (
    <StyledForm>
      <div className="container" id="contact">
        <div className="left">
          <img src={formImg} alt="form girl" />
        </div>
        <div className="right">
          <h3> Contact Us </h3>
          <p> Please contact us below for availability. </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="d-flex ">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label> Name * </Form.Label>
                <Form.Control required type="text" placeholder="name" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput2">
                <Form.Label> Email * </Form.Label>
                <Form.Control required type="email" placeholder="Email" />
              </Form.Group>
            </div>
            <div className="d-flex">
              <Form.Group controlId="exampleForm.ControlInput3">
                <Form.Label> Phone * </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Phone"
                  minlength="10"
                  maxlength="12"
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput4">
                <Form.Label> Event Date * </Form.Label>

                <Form.Control required type="date" />
              </Form.Group>
            </div>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label> Example textarea </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type your details here."
                rows={6}
              />
            </Form.Group>
            <button type="submit"> Submit </button>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </StyledForm>
  );
};

export default Index;
