import styled from "styled-components";

export const StyledCoverage = styled.div `
  .container-fluid {
    position: relative;
    text-align: center;
    & > img:nth-of-type(1) {
      position: absolute;
      left: 0;
      top: 9%;
      z-index: -1;
      @media (max-width: 975px) {
        top: 15%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    & > img:nth-of-type(2) {
      position: absolute;
      right: 0;
      top: 26%;
      z-index: -1;
      @media (max-width: 975px) {
        top: 4%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  padding-block: 12rem;
  .container {
    & > img {
      margin-block: 4rem;
      max-height: 45rem;
      width: 100%;
      height: auto;
    }
  }
  .heading {
    max-width: 91.9rem;
    margin: 0 auto;
    max-width: 91.9rem;
    h3 {
      font-family: "Futura PT";
      margin-bottom: 2.6rem;
      font-weight: 500;
      font-size: 4.2rem;
      line-height: 86.7%;
      color: #22140d;
    }

    p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 186%;

      color: #676767;
    }
  }

  .flex-container {
    padding-top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      gap: 3rem;
    }

    .item {
      text-align: center;
      max-width: 25.5rem;
      h5 {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #22140d;
        margin-bottom: 1.6rem;
        margin-top: 2.8rem;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 137.7%;
        text-align: center;
        color: #8c7954;
      }
    }
  }
`;