import styled from "styled-components";

export const StyledTestimonial = styled.div `
  background-color: #faf4ff;
  padding-block: 5.1rem;

  .container {
    text-align: center;
    h3 {
      margin-top: 5.7rem;
      margin-bottom: 4.4rem;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: 500;
      font-size: 4.2rem;
      line-height: 86.7%;
      color: #22140d;
    }

    .testimonial {
      max-width: 941px;
      margin: 0 auto;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 186.02%;
        /* or 33px */

        text-align: center;

        color: #676767;
        padding-block: 4.4rem;
      }
      h6 {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 67.7%;
        color: #22140d;
      }
    }
  }
`;