import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/Navbar";
import Hero from "./components/Hero";
import Officiant from "./components/Officiant";
import GalleryComp from "./components/Gallery";
import Coverage from "./components/Coverage";
import Testimonials from "./components/Testimonials";
import FormSection from "./components/Form";
import Footer from "./components/Footer";
import Branding from "./components/Branding";
function App() {
  return (
    <>
      <Navigation />
      <Hero />
      <Officiant />
      <GalleryComp />
      <Coverage />
      <Testimonials />
      <FormSection />
      <Footer />
      {/* <Branding /> */}
    </>
  );
}

export default App;
