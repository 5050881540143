import React from "react";
import { StyledGallery } from "./style";
import Gallery from "../../assets/images/gallery.png";
import GallerySmall from "../../assets/images/mobile-gallery.png";
const Index = () => {
  return (
    <StyledGallery>
      <h2>Weddings</h2>
      <img src={Gallery} alt="gallery" />
      <img src={GallerySmall} alt="gallery" />
    </StyledGallery>
  );
};

export default Index;
