import styled from "styled-components";

export const StyledBranding = styled.div `
  padding-block: 11.6rem;
  .container-fluid {
    position: relative;

    & > img:nth-of-type(1) {
      position: absolute;
      top: -24%;
      left: -5%;
      z-index: -1;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    & > img:nth-of-type(2) {
      position: absolute;
      bottom: -5%;
      right: 0;
      z-index: -1;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  h2 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 500;
    font-size: 6.65rem;
    line-height: 79.3%;
    /* identical to box height, or 53px */

    color: #393939;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 186.02%;

    color: #676767;
    margin-block: 5.1rem;
  }
  .d-flex {
    gap: 5rem;
  }
  .light {
    width: 193px;
    height: 193px;
    background: #ceb992;
    border-radius: 10px;
  }
  .dark {
    width: 193px;
    height: 193px;
    background: #22140d;
    border-radius: 10px;
  }
`;