import styled from "styled-components";

export const StyledForm = styled.div `
  padding-block: 12rem;
  display: flex;
  gap: 3rem;
  @media (max-width: 767px) {
   
    gap: 0;
  }
  .container {
    display: flex;
    gap: 3rem;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
     
    }
  }
  .left {
    /* max-width: 54rem; */
    width: min(54rem, 95%);
    img {
      width: 100%;
      height: 100%;
    }
    // @media (max-width: 767px) {
    //   margin-bottom: 3rem;
    // }
  }
  .right {
    max-width: 54rem;
    /* width: min(54rem, 95%); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
    & > h3 {
      font-family: "Futura PT";
      font-style: normal;
      font-weight: 500;
      font-size: 4.2rem;
      line-height: 100%;
      color: #22140d;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
    & > p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 90.02%;
      color: #676767;
      margin-top: 3.6rem;
      margin-bottom: 5rem;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
    .d-flex {
      gap: 3rem;
      /* flex-wrap: wrap; */
      justify-content: center;

      .form-control {
        width: min(25.5rem, 97%);
        height: 5rem;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 100%;
        color: black;
      }
      .form-control::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 100%;
        color: #e0e0e0 !important;
      }

      :nth-of-type(1) {
        margin-bottom: 2.3rem;
      }
      :nth-of-type(2) {
        margin-bottom: 2.9rem;
        div {
          width: 100%;
        }
      }
    }

    .form-label {
      font-family: "Poppins" !important;

      font-weight: 400 !important;
      font-size: 1.6rem !important;
      line-height: 100% !important;
      color: #000000 !important;
      margin-bottom: 1.1rem;
    }

    textarea::placeholder,
    textarea {
      /* width: min(54rem, 95%); */
      margin-bottom: 4.2rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 100%;
      color: black;
      padding: 1rem;
    }
    textarea::placeholder {
      color: #e0e0e0;
    }
    button {
      background: #ceb992;
      border-radius: 3px;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      border: none;
      padding: 1.9rem 5.1rem;
      margin-top: 4.2rem;
     
      @media (max-width: 1200px) {
       margin-block: 0;
       margin-inline: auto !important; 
      }
    }
  }
`;