import styled from "styled-components";
export const Navbar = styled.div`
  background: #faf4ff;
  div {
    min-height: calc(3.8rem + 7rem);

    display: flex;
    justify-content: space-between;
    align-items: center;
    & > img {
      max-width: 16rem;
      height: 7rem
      @media (max-width: 526px) {
        width: 142px;
        height: auto;
      }
    }
    button {
      border: none;
      padding: 1.9rem 3.5rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 67.7%;
      color: #ffffff;
      background: #ceb992;
      border-radius: 3px;
    }
  }
`;
