import styled from "styled-components";

export const StyledHero = styled.div `
  .container-fluid {
    /* display: flex;
    justify-content: center; */

    video {
      width: 100% !important;
    }

    video {
      @media (max-width: 526px) {
        /* height: 350px; */
        width: 100%;
      }
    }
  }
`;