import React from "react";
import { StyledCoverage } from "./style";
import video from "../../assets/images/event-video.png";
import left from "../../assets/images/converageleft.png";
import right from "../../assets/images/coverageright.png";
import one from "../../assets/images/event-1.png";
import two from "../../assets/images/event-2.png";
import three from "../../assets/images/event-3.png";
const Index = () => {
  return (
    <StyledCoverage>
      <div className="container-fluid">
        <div className="container">
          <div className="heading">
            <h3>Our Event Coverage</h3>
            <p>
              Feugiat nunc non sit eget a tristique ut. Et, sed pharetra cursus
              suspendisse vel id turpis. Risus dapibus gravida nisl malesuada.
            </p>
          </div>

          <img src={video} alt="video" />

          <div className="flex-container">
            <div className="item">
              <img src={one} alt="item-1" />
              <h5>Once in a Lifetime</h5>
              <p>
                Marriage is an event that occurs once in everyone’s life let’s
                make it special.
              </p>
            </div>
            <div className="item">
              <img src={two} alt="item-2" />
              <h5>Made with Love</h5>
              <p>
                We made this event memorable for the couple and their families.
              </p>
            </div>
            <div className="item">
              <img src={three} alt="item-3" />
              <h5>Special Occasion</h5>
              <p>
                This is a special occasion in everyone’s life so make it
                beautiful with us.
              </p>
            </div>
          </div>
        </div>
        <img src={left} alt="" />
        <img src={right} alt="" />
      </div>
    </StyledCoverage>
  );
};

export default Index;
