import styled from "styled-components";

export const StyledOfficiant = styled.div `
  padding-top: 2.6rem;
  padding-bottom: 11.7rem;
  .container-fluid {
    position: relative;
    & > img:nth-of-type(1) {
      position: absolute;
      top: -28%;
      left: 0;
      z-index: -1;
    }
    & > img:nth-of-type(2) {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
  }

  .heading {
    margin: 0 auto;

    max-width: 70.7rem;
    text-align: center;
    margin-bottom: 12rem;
    h3 {
      font-family: "Futura PT";
      font-weight: 500;
      font-size: 6.4rem;
      line-height: 86.7%;
      letter-spacing: 0.06em;
      color: #22140d;
    }
    p {
      margin-top: 3.8rem;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 2.4rem;
      line-height: 119.2%;
      text-align: center;
      color: #343434;
    }
  }
  .flex-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10rem;
    .left {
      width: min(49.4rem, 95%);
      h4 {
        font-family: "Futura PT";
        font-weight: 500;
        font-size: 4.2rem;
        line-height: 67.7%;
        color: #22140d;
        margin-bottom: 4rem;
      }
      p {
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 137.7%;
        color: #000000;
      }
    }
    .right {
      img {
        max-height: 44.9rem;
        width: min(95%, 51rem);
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
      align-items: center;
    }
  }
`;