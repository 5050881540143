import styled from "styled-components";

export const StyledGallery = styled.div `
  background: #faf4ff;
  padding-top: 11.8rem;
  padding-bottom: 14rem;
  text-align: center;

  h2 {
    font-family: "Futura PT";
    font-style: normal;
    font-weight: 500;
    font-size: 4.2rem;
    line-height: 67.7%;
    color: #22140d;
    margin-bottom: 8.4rem;
  }
  img:nth-of-type(1) {
    width: min(111rem, 95%);
    max-height: 134rem;
    @media (max-width: 345px) {
      display: none;
    }
  }
  img:nth-of-type(2) {
    display: none;

    @media (max-width: 345px) {
      display: block;
      width: min(34.2rem, 95%);
      max-height: 81.9rem;
      margin: 0 auto;
    }
  }
`;