import React from "react";
import { StyledFooter } from "./style";
import logo from "../../assets/images/Logo_White.png";
import fb from "../../assets/images/Facebook.png";
import twitter from "../../assets/images/twitter.png";

import insta from "../../assets/images/instagram.png";
const Index = () => {
  return (
    <StyledFooter>
      <div className="container">
        <div className="copyright">
          <p>COPYRIGHT © 2022 SHAHRZAD WEDDINGS</p>
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="icons">
        <a href="https://www.facebook.com/shahrzad.ardalan.7"> <img src={fb} alt="facebook" /></a> 
       {/* <a href=""> <img src={twitter} alt="twitter" /></a>   */}
        <a href="https://www.instagram.com/shahrzadardalan/">  <img src={insta} alt="insta" /></a>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Index;
